import { Form } from 'antd';
import { useFetchApiGet, usePostApi } from 'common/reduxutils';
import ErrorMessage from 'common/ui/ErrorMessage';
import LoadingSpinner from 'common/ui/LoadingSpinner';
import PageTitle from 'components/PageTitle';
import useTenant from 'components/use-tenant';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { submitData } from 'utils/formData';

const AdminEdit = ({
  ItemForm,
  listUrl,
  apiCall,
  resourceName,
  options = {},
  params = {},
}) => {
  const [form] = Form.useForm();
  const urlParams = useParams();
  const { business_id } = useTenant();
  const { title } = options;
  const {
    data: item,
    load: fetchItem,
    isLoading,
    error: fetchItemError,
  } = useFetchApiGet(apiCall.detail, { resourceName: resourceName || 'item' });

  const {
    post: submitItem,
    isLoading: isSubmitting,
    error: submitItemError,
  } = usePostApi(apiCall.edit);

  const {
    post: deleteItem,
    isLoading: isDeleting,
    error: deleteItemError,
  } = usePostApi(apiCall.delete);

  useEffect(() => {
    fetchItem(urlParams);
  }, []);

  const onFinish = (values, photoFields = ['photo']) => {
    console.debug('Received values of form: ', values);

    submitData(submitItem, { business_id, ...values }, urlParams, photoFields);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (fetchItemError) {
    return <ErrorMessage {...fetchItemError} />;
  }

  return (
    <>
      <PageTitle title={`${title || 'Edit Item'} `} backUrl={listUrl} />
      <ItemForm
        formMode="edit"
        params={params}
        submiting={isSubmitting}
        submitItemError={submitItemError}
        deleting={isDeleting}
        deleteItemError={deleteItemError}
        form={form}
        onFinish={onFinish}
        initialValues={{ ...item }}
        name="edit_form"
        visibility="visible"
        onDelete={() => {
          if (confirm('Are you sure?')) {
            deleteItem({ business_id, ...urlParams });
          }
        }}
      />
    </>
  );
};

export default AdminEdit;
