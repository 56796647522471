import { Button, Form, Input, Switch } from 'antd';
import apiCall from 'apiCalls/category';
import { usePostApi } from 'common/reduxutils';
import useTenant from 'components/use-tenant';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';

const ItemForm = ({
  form,
  submiting,
  onFinish,
  initialValues = {
    is_featured: false,
    ordering: 0,
  },
  name,
}) => {
  const { id } = useParams();
  const { business_id } = useTenant();

  const {
    post: deleteItem,
    isLoading: isDeleting,
    error: deleteItemError,
  } = usePostApi(apiCall.delete);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  return (
    <Form
      {...formItemLayout}
      form={form}
      name={name || 'category_form'}
      onFinish={values => onFinish(values)}
      initialValues={initialValues}
      scrollToFirstError
    >
      <Form.Item name="name" label="Name (English)">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item name="name_ms" label="Name (Malay)">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item name="name_zh" label="Name (Chinese)">
        <Input placeholder="" />
      </Form.Item>

      <Form.Item name="ordering" label="Ordering">
        <Input placeholder="" />
      </Form.Item>

      <Form.Item
        name="is_featured"
        label="Is Featured?"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>

      {/* <Form.Item name="description" label="Description (English)">
        <Input.TextArea rows={10} />
      </Form.Item>
      <Form.Item name="description_ms" label="Description (Malay)">
        <Input.TextArea rows={10} />
      </Form.Item>
      <Form.Item name="description_zh" label="Description (Chinese)">
        <Input.TextArea rows={10} />
      </Form.Item> */}

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" loading={submiting}>
          Save
        </Button>
        {id && (
          <Button
            danger
            loading={isDeleting}
            onClick={() => {
              if (confirm('Are you sure?')) {
                deleteItem({ business_id, id });
              }
            }}
          >
            Delete
          </Button>
        )}
      </Form.Item>
    </Form>
  );
};

export default ItemForm;
