import { getTenantUrl } from 'utils/urlHelpers';

export function getItemLinkPrefix(business_id, itemType) {
  switch (itemType) {
    case 'expense':
      return getTenantUrl(business_id, '/expenses');
    case 'sales':
      return getTenantUrl(business_id, '/orders');
    case 'purchase':
      return getTenantUrl(business_id, '/purchase-orders');
    case 'payment':
      return getTenantUrl(business_id, '/payment/transactions');
    default:
      return '';
  }
}
