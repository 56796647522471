import { Button, Card, Descriptions, Space, Tag, Typography } from 'antd';
import { Link } from 'react-router-dom';

const { Title } = Typography;

const BusinessCard = ({ business, user }) => {
  const isOwner = record => business.owner === user.id;

  return (
    <Card
      size="small"
      title={
        <Title level={5} className="my-2">
          <Space className="items-end">
            <Link to={`/${business.id}`}>{business.name}</Link>
            {isOwner(business) && (
              <Tag className="cursor-default" color="#52c41a">
                Owner
              </Tag>
            )}
          </Space>
        </Title>
      }
      extra={
        <Button>
          <Link to={`/${business.id}`}>Select</Link>
        </Button>
      }
    >
      <Descriptions column={1}>
        <Descriptions.Item label="Company Name">
          {business.name}
        </Descriptions.Item>
        <Descriptions.Item label="Phone">{business.phone}</Descriptions.Item>
        <Descriptions.Item label="Address">
          {business.address}
        </Descriptions.Item>
        <Descriptions.Item label="Domain">
          {business.domain_name}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

export default BusinessCard;
