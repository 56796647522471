import apiCall from 'apiCalls/manager';
import {
  CreatePage,
  DetailPage,
  EditPage,
  ListPage,
} from 'common/ui/AdminCRUD/page';
import useTenant from 'components/use-tenant';
import ItemForm from './Form';
import { columnsGenerator } from './config';
import ItemDetail from './detail';

export const ManagerListPage = () => {
  const { business_id } = useTenant();
  return (
    <ListPage
      title="All Managers"
      namespace="managers"
      searchOptions={{ tooltip: 'Enter name, email or phone number' }}
      apiCall={apiCall}
      columnGenerator={() => columnsGenerator({ business_id })}
    />
  );
};

export const ManagerEditPage = () => {
  return (
    <EditPage
      title="Managers"
      namespace="managers"
      apiCall={apiCall}
      ItemForm={ItemForm}
      options={{ title: 'Edit account' }}
      resourceName=""
    />
  );
};

export const ManagerDetailPage = () => {
  return (
    <DetailPage
      title="Managers"
      namespace="managers"
      apiCall={apiCall}
      ItemDetail={ItemDetail}
      resourceName=""
    />
  );
};

export const ManagerCreatePage = () => (
  <CreatePage
    title="Managers"
    namespace="managers"
    apiCall={apiCall}
    ItemForm={ItemForm}
    resourceName=""
  />
);
