import { Space, Tag } from 'antd';
import { journalStatusColors, journalStatusLabels } from 'constants/journal';
import { capitalize } from 'lodash';
import { Link } from 'react-router-dom';
import { getTenantUrl } from 'utils/urlHelpers';

import { formatDate } from 'utils/dateUtils';
import { formatCurrency } from 'utils/formatAmount';
import { getItemLinkPrefix } from 'utils/journal';

export const columnsGenerator = ({ business_id }) => [
  {
    title: 'Creditor ID',
    dataIndex: 'creditor_code',
  },
  {
    title: 'Company Name',
    dataIndex: 'name',
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    align: 'right',
  },
  {
    title: 'Statement Balance',
    dataIndex: 'balance',
    align: 'right',
    render: value => formatCurrency(value),
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Space>
        <Link to={getTenantUrl(business_id, `/suppliers/${record.id}`)}>
          View
        </Link>
        <Link
          to={getTenantUrl(business_id, `/suppliers/${record.id}/statement`)}
        >
          Statement
        </Link>
      </Space>
    ),
  },
];

export const statementColumns = () => [
  {
    title: 'Date',
    dataIndex: 'date',
    render: value => formatDate(value),
  },
  {
    title: 'Particulars',
    dataIndex: 'item_code',
    align: 'left',
    render: (item_code, record) => (
      <>
        {capitalize(record.item_type)}{' '}
        <Link to={`${getItemLinkPrefix(record.item_type)}/${record.item_id}`}>
          {item_code}
        </Link>
      </>
    ),
  },
  {
    title: 'Debit',
    dataIndex: 'debit',
    align: 'right',
    render: value => (value > 0 ? formatCurrency(value) : ''),
  },
  {
    title: 'Credit',
    dataIndex: 'credit',
    align: 'right',
    render: value => (value > 0 ? formatCurrency(value) : ''),
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    align: 'right',
    render: value => formatCurrency(value),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    align: 'center',
    render: status => {
      return (
        <Tag color={journalStatusColors[status]}>
          {journalStatusLabels[status]}
        </Tag>
      );
    },
  },
];
