import { StarFilled } from '@ant-design/icons';
import { Button, Tag } from 'antd';
import { journalStatusColors, journalStatusLabels } from 'constants/journal';
import { capitalize } from 'lodash';
import { Link } from 'react-router-dom';
import { formatDate } from 'utils/dateUtils';
import { formatCurrency } from 'utils/formatAmount';
import { getItemLinkPrefix } from 'utils/journal';
import { getTenantUrl } from 'utils/urlHelpers';

export const columnsGenerator = ({ business_id }) => [
  {
    title: 'Ordering',
    dataIndex: 'ordering',
    align: 'center',
  },
  {
    title: 'ID',
    dataIndex: 'code',
    align: 'center',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    render: (value, record) => {
      if (!record.is_featured) return value;
      return (
        <>
          <StarFilled spin style={{ color: '#ffec3d', border: '' }} /> {value}
        </>
      );
    },
  },
  {
    title: 'Statement Balance',
    dataIndex: 'balance',
    align: 'right',
    render: value => formatCurrency(value),
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Button.Group>
        <Button shape="round">
          <Link to={getTenantUrl(business_id, `/expense-types/${record.id}`)}>
            View
          </Link>
        </Button>
        <Button shape="round">
          <Link
            to={getTenantUrl(business_id, `/expense-types/${record.id}/edit`)}
          >
            Edit
          </Link>
        </Button>
        <Button shape="round">
          <Link
            to={getTenantUrl(
              business_id,
              `/expense-types/${record.id}/statement`
            )}
          >
            Statement
          </Link>
        </Button>
      </Button.Group>
    ),
  },
];

export const statementColumns = ({ business_id }) => [
  {
    title: 'Date',
    dataIndex: 'date',
    render: value => formatDate(value),
  },
  {
    title: 'Particulars',
    dataIndex: 'item_code',
    align: 'left',
    render: (item_code, record) => (
      <>
        {capitalize(record.item_type)}{' '}
        <Link
          to={`${getItemLinkPrefix(business_id, record.item_type)}/${record.item_id}`}
        >
          {item_code}
        </Link>
      </>
    ),
  },
  {
    title: 'Branch',
    dataIndex: 'branch',
    align: 'center',
    render: value => (value ? value.name : '-'),
  },
  {
    title: 'Debit',
    dataIndex: 'debit',
    align: 'right',
    render: value => (value > 0 ? formatCurrency(value) : ''),
  },
  {
    title: 'Credit',
    dataIndex: 'credit',
    align: 'right',
    render: value => (value > 0 ? formatCurrency(value) : ''),
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    align: 'right',
    render: value => formatCurrency(value),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    align: 'center',
    render: status => {
      return (
        <Tag color={journalStatusColors[status]}>
          {journalStatusLabels[status]}
        </Tag>
      );
    },
  },
];
