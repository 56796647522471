import { Space } from 'antd';
import { Link } from 'react-router-dom';
import { getTenantUrl } from 'utils/urlHelpers';

import { formatDateTime } from 'utils/dateUtils';

export const columnsGenerator = ({ business_id }) => [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    align: 'right',
  },
  {
    title: 'Date Joined',
    dataIndex: 'date_joined',
    sorter: true,
    render: value => formatDateTime(value),
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Space>
        <Link to={getTenantUrl(business_id, `/managers/${record.id}`)}>
          View
        </Link>
        <Link to={getTenantUrl(business_id, `/managers/${record.id}/edit`)}>
          Edit
        </Link>
      </Space>
    ),
  },
];
