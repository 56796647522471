import { Card, Descriptions, Typography } from 'antd';
import { formatDateTime } from 'utils/dateUtils';

const { Item: DescItem } = Descriptions;
const { Title } = Typography;

const ItemDetail = ({ extraActions, item }) => {
  return (
    <div className="lg:w-2/3 w-full justify-center">
      <div className="flex flex-col py-4">
        <Card
          title={<Title level={3}>Account Detail</Title>}
          extra={extraActions}
        >
          <Descriptions column={1} bordered>
            <DescItem label="Company Name">{item.name}</DescItem>
            <DescItem label="Person in charge">
              {item.person_in_charge}
            </DescItem>
            <DescItem label="Phone">{item.phone}</DescItem>
            <DescItem label="Email">{item.email}</DescItem>
            <DescItem label="Address 1">{item.address1}</DescItem>
            <DescItem label="Address 2">{item.address2}</DescItem>
            <DescItem label="Address 3">{item.address3}</DescItem>
            <DescItem label="TIN">{item.tax_number}</DescItem>
            <DescItem label="Admin notes">{item.admin_notes}</DescItem>
            <DescItem label="Joined At">
              {formatDateTime(item.date_joined)}
            </DescItem>
          </Descriptions>
        </Card>
      </div>
    </div>
  );
};

export default ItemDetail;
