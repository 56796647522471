import { Card, Descriptions, Typography } from 'antd';

import { formatDateTime } from 'utils/dateUtils';

const { Item: DescItem } = Descriptions;
const { Title } = Typography;

const ItemDetail = ({ extraActions, item }) => {
  const { id, name, email, phone, date_joined } = item || {};

  return (
    <div className="lg:w-2/3 w-full justify-center">
      <div className="flex flex-col py-4">
        <Card
          title={<Title level={3}>Account Detail</Title>}
          extra={extraActions}
        >
          <Descriptions column={1} bordered>
            <DescItem label="Name">{name}</DescItem>
            <DescItem label="Email">{email}</DescItem>
            <DescItem label="Phone">{phone}</DescItem>
            <DescItem label="Joined At">{formatDateTime(date_joined)}</DescItem>
          </Descriptions>
        </Card>
      </div>
    </div>
  );
};

export default ItemDetail;
