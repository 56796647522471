import apiCall from 'apiCalls/business';
import { useFetchApiGet } from 'common/reduxutils';
import LoadingSpinner from 'common/ui/LoadingSpinner';
import useAdminAuth from 'hooks/useAdminAuth';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import TenantUserMenu from './UserMenuTenant';

const Header = () => {
  const { user, isAuthenticated, loading, signOut } = useAdminAuth();
  const { business_id } = useParams();

  const {
    data: item,
    load: fetchItem,
    isLoading,
  } = useFetchApiGet(apiCall.detail, { resourceName: 'item' });

  useEffect(() => {
    fetchItem({ id: business_id });
  }, [business_id]);

  return (
    <header className="text-gray-600 body-font">
      <div className="flex flex-wrap md:justify-between flex-col md:flex-row p-5 items-center">
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <Link
            className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0"
            to={`/${business_id}`}
          >
            <span className="ml-3 text-xl">{item.name}</span>
          </Link>
        )}
        <div className="md:ml-auto flex flex-wrap items-center text-base justify-center"></div>
        <div className="inline-flex items-center bg-gray-100 border-0 p-1 focus:outline-none hover:bg-gray-200 rounded text-base mt-4 md:mt-0">
          {loading ? (
            '..'
          ) : isAuthenticated ? (
            <TenantUserMenu user={user} signOut={signOut} />
          ) : null}
        </div>
      </div>
    </header>
  );
};

export default Header;
