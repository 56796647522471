import { Button, Form, Input, Space } from 'antd';
import apiCall from 'apiCalls/expenseType';
import { usePostApi } from 'common/reduxutils';
import useTenant from 'components/use-tenant';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';

const ItemForm = ({ form, submiting, onFinish, initialValues = {}, name }) => {
  const { id } = useParams();
  const { business_id } = useTenant();

  const {
    post: deleteItem,
    isLoading: isDeleting,
    error: deleteItemError,
  } = usePostApi(apiCall.delete);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  return (
    <Form
      {...formItemLayout}
      form={form}
      name={name || 'expense_type_form'}
      onFinish={values => onFinish(values)}
      initialValues={initialValues}
      scrollToFirstError
    >
      <Form.Item label="Expense ID">
        {initialValues.code || '[auto generated]'}
      </Form.Item>
      <Form.Item name="name" label="Name (English)">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item name="name_ms" label="Name (Malay)">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item name="name_zh" label="Name (Chinese)">
        <Input placeholder="" />
      </Form.Item>

      <Form.Item name="ordering" label="Ordering">
        <Input placeholder="" />
      </Form.Item>

      <Form.Item name="admin_notes" label="Admin notes">
        <Input.TextArea rows={5} />
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Space>
          <Button type="primary" htmlType="submit" loading={submiting}>
            Save
          </Button>
          {id && (
            <Button
              danger
              loading={isDeleting}
              onClick={() => {
                if (confirm('Are you sure?')) {
                  deleteItem({ business_id, id });
                }
              }}
            >
              Delete
            </Button>
          )}
        </Space>
      </Form.Item>
    </Form>
  );
};

export default ItemForm;
