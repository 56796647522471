import { Button } from 'antd';
import { useFetchApiGet } from 'common/reduxutils';
import ErrorMessage from 'common/ui/ErrorMessage';
import LoadingSpinner from 'common/ui/LoadingSpinner';
import PageTitle from 'components/PageTitle';
import useTenant from 'components/use-tenant';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getTenantUrl } from 'utils/urlHelpers';

const AdminDetail = ({
  namespace,
  listUrl,
  apiCall,
  options = { title: 'View detail', deleteEnabled: false },
  ItemDetail,
  resourceName,
}) => {
  const params = useParams();
  const { business_id } = useTenant();
  const { title, deleteEnabled } = options;
  const {
    data: item,
    load: fetchItem,
    isLoading,
    error: fetchItemError,
  } = useFetchApiGet(apiCall.detail, { resourceName: resourceName });

  useEffect(() => {
    fetchItem({ business_id, id: params.id });
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (fetchItemError) {
    return <ErrorMessage {...fetchItemError} />;
  }

  return (
    <>
      <PageTitle title={title} backUrl={listUrl} />
      <ItemDetail
        item={item}
        business_id={business_id}
        apiCall={apiCall}
        extraActions={
          <Button type="primary">
            <Link
              to={getTenantUrl(business_id, `/${namespace}/${item.id}/edit`)}
            >
              Edit
            </Link>
          </Button>
        }
      />
    </>
  );
};

export default AdminDetail;
