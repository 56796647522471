import apiCall from 'apiCalls/order';
import {
  CreatePage,
  DetailPage,
  EditPage,
  ListPage,
} from 'common/ui/AdminCRUD/page';
import useTenant from 'components/use-tenant';
import { columnsGenerator } from './config';
import ItemDetail from './detail';
import OrderForm from './forms/OrderForm';
import InvoiceItemDetail from './invoice';

export const OrderListPage = () => {
  const { business_id } = useTenant();
  return (
    <ListPage
      title="All Sales Orders"
      namespace="orders"
      searchOptions={{ tooltip: 'Search by invoice number, username or email' }}
      filterOptions={{ filters: ['date', 'users'] }}
      apiCall={apiCall}
      columnGenerator={() => columnsGenerator({ business_id })}
    />
  );
};

export const OrderDetailPage = props => (
  <DetailPage
    title="Sale Detail"
    namespace="orders"
    apiCall={apiCall}
    ItemDetail={ItemDetail}
    resourceName=""
  />
);

export const OrderInvoicePage = props => (
  <DetailPage
    title="Sale Invoice"
    namespace="orders"
    apiCall={apiCall}
    ItemDetail={InvoiceItemDetail}
    resourceName=""
    {...props}
  />
);

export const OrderEditPage = props => (
  <EditPage
    title="Sale Edit"
    namespace="orders"
    apiCall={apiCall}
    ItemForm={OrderForm}
    resourceName=""
    {...props}
  />
);

export const OrderCreatePage = props => (
  <CreatePage
    title="Sale Create"
    namespace="orders"
    apiCall={apiCall}
    ItemForm={OrderForm}
    resourceName=""
    {...props}
  />
);
