import apiCall from 'apiCalls/admin';
import {
  CreatePage,
  DetailPage,
  EditPage,
  ListPage,
} from 'common/ui/AdminCRUD/page';
import useTenant from 'components/use-tenant';
import { useParams } from 'react-router-dom';
import ItemForm from './Form';
import { columnsGenerator } from './config';
import ItemDetail from './detail';

export const AdminListPage = () => {
  const { business_id } = useTenant();
  return (
    <ListPage
      title="All Administrators"
      namespace="administrators"
      searchOptions={{ tooltip: 'Enter name, email or phone number' }}
      apiCall={apiCall}
      columnGenerator={() => columnsGenerator({ business_id })}
    />
  );
};

export const AdminEditPage = props => {
  const { id } = useParams();

  return (
    <EditPage
      title="Administrators"
      namespace="administrators"
      apiCall={apiCall}
      ItemForm={ItemForm}
      options={{ title: 'Edit account' }}
      resourceName=""
      {...props}
    />
  );
};

export const AdminDetailPage = props => {
  return (
    <DetailPage
      title="Administrators"
      namespace="administrators"
      apiCall={apiCall}
      ItemDetail={ItemDetail}
      resourceName=""
      {...props}
    />
  );
};

export const AdminCreatePage = props => (
  <CreatePage
    title="Administrators"
    apiCall={apiCall}
    ItemForm={ItemForm}
    resourceName=""
    {...props}
  />
);
