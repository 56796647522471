import { enhancedMakeFetchAction } from 'common/reduxutils';
import { generateEndpoint } from 'utils/urlHelpers';

const CREATE_ADMIN_API_ID = 'CREATE_ADMIN_API_ID';

const apiCall = enhancedMakeFetchAction(
  CREATE_ADMIN_API_ID,
  ({ business_id, payload }) => ({
    endpoint: generateEndpoint({
      path: `/tenant/v1/businesses/${business_id}/staffs`,
    }),
    method: 'POST',
    body: { ...payload, account_type: 'admin' },
    notify: true,
  })
);

export default apiCall;
