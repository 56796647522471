import { UploadOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  notification,
  Select,
  Space,
  Tag,
  Typography,
  Upload,
} from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import branchApiCall from 'apiCalls/branch';
import apiCall from 'apiCalls/expense';
import { default as expenseTypeApiCall } from 'apiCalls/expenseType';
import txAccountApiCall from 'apiCalls/TxAccount';
import DataTable from 'common/ui/DataTable';
import DebounceSelect from 'common/ui/DebouceSelect';
import XModal from 'components/XModal';
import { expenseStatusColors, expenseStatusLabels } from 'constants/expense';
import { statusOptions as options } from 'constants/status';
import { useFormBlocker } from 'hooks';
import { formatCurrency } from 'utils/formatAmount';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';
import {
  convertDateFormData,
  ensureDateFields,
  FormattedError,
  submitData,
  userSelectDisplayFn,
} from 'utils/formData';

import useTenant from 'components/use-tenant';
import ExpenseItemForm from './ExpenseItemForm';

const { Title } = Typography;

const dateFields = ['date'];

const useExpenseQuery = (business_id, id) => {
  return useQuery({
    queryKey: [apiCall.detail.queryKey, id],
    queryFn: () => {
      if (id) {
        return apiCall.detail.queryFn({ business_id, id });
      }

      return Promise.resolve('');
    },
  });
};

const ensureFormData = values => {
  let payload = ensureDateFields(values, dateFields);
  if (payload.user && payload.user.id) {
    payload = {
      ...payload,
      user: values.user.id,
    };
  }

  if (payload.expense_type && payload.expense_type.id) {
    payload = {
      ...payload,
      expense_type: payload.expense_type.id,
    };
  }

  if (payload.branch && payload.branch.id) {
    payload = {
      ...payload,
      branch: payload.branch.id,
    };
  }

  return payload;
};

const ExpenseForm = ({
  form,
  initialValues = { status: 1001 },
  name,
  formMode = 'create',
}) => {
  const setShouldBlock = useFormBlocker(form);
  const navigate = useNavigate();
  const urlParams = useParams();
  const { id } = urlParams;
  const formData = Form.useWatch([], form) || {};
  const { business_id } = useTenant();

  // reference data hooks
  const expenseId = id || formData.id;
  const { data: item = {} } = useExpenseQuery(business_id, expenseId);

  const [shouldGoBack, setShouldGoBack] = useState(false);
  const { mutate: createItem, isLoading: isCreating } = useMutation({
    mutationFn: apiCall.create.queryFn,
    onSuccess: createdItem => {
      form.setFieldsValue({
        id: createdItem.id,
        code: createdItem.code,
        status: createdItem.status,
      });
      if (shouldGoBack) {
        notification.open({
          type: 'success',
          message: 'Success',
        });
        navigate(-1);
      } else {
        notification.open({ message: 'Saved' });
      }
    },
    onError: error => {
      notification.open({
        type: 'error',
        message: 'Error!',
        description: <FormattedError error={error} />,
        duration: 10,
      });
    },
  });

  const { mutate: updateItem, isLoading: isUpdating } = useMutation({
    mutationFn: apiCall.edit.queryFn,
    onSuccess: () => {
      if (shouldGoBack) {
        notification.open({
          type: 'success',
          message: 'Success',
        });
        navigate(-1);
      } else {
        notification.open({ message: 'Saved' });
      }
    },
    onError: error => {
      notification.open({
        type: 'error',
        message: 'Error!',
        description: <FormattedError error={error} />,
        duration: 10,
      });
    },
  });
  const { mutate: deleteItem, isLoading: isDeleting } = useMutation({
    mutationFn: apiCall.delete.queryFn,
    onSuccess: () => {
      notification.open({
        type: 'success',
        message: 'Success',
      });
      navigate(-1);
    },
    onError: error => {
      notification.open({
        type: 'error',
        message: 'Error!',
        description: <FormattedError error={error} />,
        duration: 10,
      });
    },
  });

  const dataItem = { ...initialValues, ...item };

  // detect changes for auto save
  useEffect(() => {
    const isAutoSave =
      !formData.id &&
      !!formData.user &&
      !!formData.date &&
      !!formData.expense_type;

    if (isAutoSave) {
      setShouldBlock(false);
      form.submit();
    }
  }, [formData]);

  return (
    <Form
      {...formItemLayout}
      form={form}
      initialValues={ensureFormData(dataItem)}
      name={name || 'expense_form'}
      onFieldsChange={() => setShouldBlock(!!formData.id)}
      onFinish={values => {
        setShouldBlock(false);
        if (values.id) {
          return submitData(
            updateItem,
            convertDateFormData(values, dateFields),
            urlParams,
            ['file']
          );
        }

        return submitData(
          createItem,
          convertDateFormData(values, dateFields),
          urlParams,
          ['file']
        );
      }}
      scrollToFirstError
    >
      <Form.Item name="id">
        <Input type="hidden" />
      </Form.Item>
      <Form.Item label="Expense No.">
        {dataItem.code || '[auto generated]'}
      </Form.Item>
      {formMode == 'edit' && dataItem && dataItem.id > 0 ? (
        <Form.Item name="status" label="Status">
          <Select>
            {options.map(option => (
              <Select.Option key={option.value} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      ) : (
        <Form.Item label="Status">
          <Tag color={expenseStatusColors[item.status || 1000]}>
            {expenseStatusLabels[item.status || 1000]}
          </Tag>
        </Form.Item>
      )}
      <Form.Item name="expense_type" label="Expense Type" required>
        <DebounceSelect
          apiCall={expenseTypeApiCall.list}
          params={
            initialValues.expense_type
              ? { business_id, id: initialValues.expense_type.id }
              : { business_id }
          }
          placeholder="Select expense type"
          fieldNames={{ value: 'id', label: 'name' }}
        />
      </Form.Item>
      <Form.Item name="branch" label="Branch">
        <DebounceSelect
          apiCall={branchApiCall.list}
          params={
            initialValues.branch
              ? { business_id, id: initialValues.branch.id }
              : { business_id }
          }
          placeholder="Select branch"
          fieldNames={{ value: 'id', label: 'name' }}
        />
      </Form.Item>
      <Form.Item name="user" label="Account" required>
        <DebounceSelect
          apiCall={txAccountApiCall.list}
          params={
            initialValues.user
              ? { business_id, id: initialValues.user.id }
              : {
                  business_id,
                  account_type__in: 'customer,supplier,payment',
                }
          }
          placeholder="Select account (creditor / bank & cash account)"
          displayFn={userSelectDisplayFn}
          fieldNames={{ label: 'name', value: 'id' }}
          onSelect={(_, selectedAccount) => {
            // Only pre-fill for non-payment accounts (creditor or debitor accounts)
            if (selectedAccount && selectedAccount.account_type != 'payment') {
              form.setFieldsValue({
                billing_address: selectedAccount.address1,
                // contact_person: selectedAccount.personInCharge,
                phone_number: selectedAccount.phone,
                email: selectedAccount.email,
              });
            }
          }}
        />
      </Form.Item>
      <Form.Item name="billing_address" label="Billing Address">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item name="contact_person" label="Contact Person">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item name="phone_number" label="Phone Number">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item name="email" label="Email Address">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item name="reference_number" label="Reference Number">
        <Input placeholder="" />
      </Form.Item>

      <Form.Item name="date" label="Date" required>
        <DatePicker format={'YYYY-MM-DD'} />
      </Form.Item>

      <Card className="my-5" title={<Title level={3}>Expense Items</Title>}>
        <XModal title={'New item'} isReady={!!formData.id}>
          <ExpenseItemForm params={{ expenseId: expenseId }} />
        </XModal>
        <DataTable
          rowKey="id"
          columns={[
            {
              title: 'Particular',
              dataIndex: 'particular',
            },
            {
              title: 'Quantity',
              dataIndex: 'quantity',
              align: 'right',
            },
            {
              title: 'Price',
              dataIndex: 'price',
              align: 'right',
              render: value => formatCurrency(value),
            },
            {
              title: 'Amount',
              dataIndex: 'amount',
              align: 'right',
              render: value => formatCurrency(value),
            },
            {
              title: 'Tax',
              dataIndex: 'tax',
              align: 'right',
              render: value => formatCurrency(value),
            },
            {
              title: 'Action',
              key: 'action',
              render: (text, record) => (
                <XModal title={'Edit item'}>
                  <ExpenseItemForm
                    params={{ id: record.id, expenseId: expenseId }}
                  />
                </XModal>
              ),
            },
          ]}
          dataSource={dataItem?.expense_items}
          totalItems={dataItem?.expense_items?.length}
          currentPage={1}
          defaultCurrent={1}
        />
        <div className="grid grid-cols-5 gap-4">
          <div className="col-span-3"></div>
          <div className="">
            <p>Sub Total:</p>
            <p>Discount Given:</p>
            <p>Rounding Adjustment:</p>
            <p>Total Amount:</p>
          </div>
          <div className="">
            <p>{formatCurrency(dataItem.sub_total)}</p>
            <p>{formatCurrency(dataItem.discount)}</p>
            <p>{formatCurrency(dataItem.rounding_adjustment)}</p>
            <p>{formatCurrency(dataItem.amount)}</p>
          </div>
        </div>
      </Card>

      <Form.Item name="notes" label="Notes">
        <Input.TextArea rows={5} />
      </Form.Item>
      <Form.Item name="personal_notes" label="Personal Notes">
        <Input.TextArea rows={5} />
      </Form.Item>

      <Form.Item
        name="file"
        label="Attachment"
        valuePropName="fileList "
        getValueFromEvent={e => {
          if (Array.isArray(e)) {
            return e;
          }
          return e && e.fileList;
        }}
      >
        <Upload.Dragger listType="picture" beforeUpload={() => false}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload.Dragger>
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            loading={isCreating || isUpdating}
            onClick={() => {
              setShouldGoBack(true);
            }}
          >
            Save
          </Button>
          {id && (
            <Button
              danger
              loading={isDeleting}
              onClick={() => {
                if (confirm('Are you sure?')) {
                  deleteItem({ business_id, id });
                }
              }}
            >
              Delete
            </Button>
          )}
        </Space>
      </Form.Item>
    </Form>
  );
};

export default ExpenseForm;
