import { Button, Tag } from 'antd';
import { orderStatusColors, purchaseOrderStatusLabels } from 'constants/order';
import { Link } from 'react-router-dom';
import { formatDate, formatDateTime } from 'utils/dateUtils';
import { formatCurrency } from 'utils/formatAmount';
import { getTenantUrl } from 'utils/urlHelpers';

export const columnsGenerator = ({ business_id }) => [
  {
    title: 'Date',
    dataIndex: 'date',
    align: 'center',
    render: value => formatDate(value),
  },
  {
    title: 'Invoice Number',
    dataIndex: 'code',
    align: 'center',
  },
  {
    title: 'Creditor Name',
    dataIndex: 'user',
    render: user => {
      if (!user) return null;
      return user.name;
    },
  },
  {
    title: 'Amount',
    dataIndex: 'total_amount',
    align: 'right',
    render: value => formatCurrency(value),
  },
  {
    title: 'Modifed Date',
    dataIndex: 'modified',
    render: value => formatDateTime(value),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    align: 'center',
    render: status => {
      return (
        <Tag color={orderStatusColors[status]}>
          {purchaseOrderStatusLabels[status]}
        </Tag>
      );
    },
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Button.Group>
        <Button shape="round">
          <Link to={getTenantUrl(business_id, `/purchase-orders/${record.id}`)}>
            View
          </Link>
        </Button>
        <Button
          shape="round"
          disabled={record.status == 1002 || record == 1003}
        >
          <Link
            to={getTenantUrl(business_id, `/purchase-orders/${record.id}/edit`)}
          >
            Edit
          </Link>
        </Button>
        <Button shape="round">
          <Link
            to={getTenantUrl(
              business_id,
              `/purchase-orders/${record.id}/invoice`
            )}
          >
            Invoice
          </Link>
        </Button>
      </Button.Group>
    ),
  },
];
